import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid list-products" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'indexProductlist',
  setup(__props) {

const listTable = defineAsyncComponent(() => import("@/components/ecommerce/productlist/listTable.vue"))

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "E-Commerce",
      title: "Product List"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "card-header pb-0" }, [
              _createElementVNode("h5", null, "Individual column searching (text inputs) "),
              _createElementVNode("span", null, "The searching functionality provided by DataTables is useful for quickly search through the information in the table - however the search is global, and you may wish to present controls that search on specific columns.")
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_unref(listTable))
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})