import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "logo-wrapper",
  style: {"text-align":"center","margin-top":"0px"}
}

import { defineAsyncComponent, ref } from "vue";
import { useMenuStore } from '@/store/menu'

export default /*@__PURE__*/_defineComponent({
  __name: 'indexView',
  setup(__props) {

const logoView = defineAsyncComponent(() => import('./logoView.vue'))
const navView = defineAsyncComponent(() => import('./navView.vue'))

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_unref(logoView)),
      _createElementVNode("div", {
        class: "back-btn",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(useMenuStore)().toggle_sidebar && _unref(useMenuStore)().toggle_sidebar(...args)))
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("i", { class: "fa fa-angle-left" }, null, -1)
      ]))
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "logo-icon-wrapper" }, null, -1)),
    _createVNode(_unref(navView))
  ]))
}
}

})