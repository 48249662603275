import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row product-page-main p-0" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'productPage',
  setup(__props) {

const productSwiper = defineAsyncComponent(() => import("@/components/ecommerce/productpage/productSwiper.vue"))
const pageDetails = defineAsyncComponent(() => import("@/components/ecommerce/productpage/pageDetails.vue"))
const filterBlock = defineAsyncComponent(() => import("@/components/ecommerce/productpage/filterBlock.vue"))
const prodectpageTab = defineAsyncComponent(() => import("@/components/ecommerce/productpage/prodectpageTab.vue"))

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Ecommerce",
      title: "Product Page"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_unref(productSwiper)),
          _createVNode(_unref(pageDetails)),
          _createVNode(_unref(filterBlock))
        ])
      ]),
      _createVNode(_unref(prodectpageTab))
    ])
  ], 64))
}
}

})