import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }

import { defineAsyncComponent } from 'vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'indexApexchart',
  setup(__props) {

const columnChart = defineAsyncComponent(() => import("@/components/charts/apexchart/columnChart.vue"))


const radialBar = defineAsyncComponent(() => import('@/components/charts/apexchart/radialBar.vue'))

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_areaSpaline = _resolveComponent("areaSpaline")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Charts",
      title: "ApexChart"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_areaSpaline),
        _createVNode(_unref(columnChart)),
        _createVNode(_unref(radialBar))
      ])
    ])
  ], 64))
}
}

})