import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "col-lg-12 question-block" }
const _hoisted_2 = {
  class: "row default-according style-1 faq-accordion",
  id: "accordionoc"
}
const _hoisted_3 = { class: "col-xl-10 col-lg-10 col-md-10 box-col-70 col-sm-12 question-block" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-header" }
const _hoisted_6 = { class: "mb-0" }
const _hoisted_7 = ["aria-expanded"]
const _hoisted_8 = { class: "card" }
const _hoisted_9 = { class: "card-header" }
const _hoisted_10 = { class: "mb-0" }
const _hoisted_11 = ["aria-expanded"]
const _hoisted_12 = { class: "card" }
const _hoisted_13 = { class: "card-header" }
const _hoisted_14 = { class: "mb-0" }
const _hoisted_15 = ["aria-expanded"]
const _hoisted_16 = { class: "card" }
const _hoisted_17 = { class: "card-header" }
const _hoisted_18 = { class: "mb-0" }
const _hoisted_19 = ["aria-expanded"]

import { ref } from "vue";
import { defineAsyncComponent } from "vue";
// const intellectualProperty = defineAsyncComponent(
//   () => import("./intellectualProperty.vue")
// );
// const navigationView = defineAsyncComponent(
//   () => import("./navigationView.vue")
// );

export default /*@__PURE__*/_defineComponent({
  __name: 'faqsSupport',
  setup(__props) {

const show = ref<boolean>(false);
const show1 = ref<boolean>(false);
const show3 = ref<boolean>(false);
const show2 = ref<boolean>(false);
function open3() {
  show3.value = !show3.value;
}
function open() {
  show.value = !show.value;
}
function open1() {
  show1.value = !show1.value;
}
function open2() {
  show2.value = !show2.value;
}

return (_ctx: any,_cache: any) => {
  const _component_vue_feather = _resolveComponent("vue-feather")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[13] || (_cache[13] = _createElementVNode("h1", { class: "heading-title" }, " FAQs Support ", -1)),
    _cache[14] || (_cache[14] = _createElementVNode("p", { class: "col-lg-10 col-sm-10 question-block paragraph" }, " Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nisi, rem? Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, nemo! ", -1)),
    _createElementVNode("div", _hoisted_1, [
      _cache[12] || (_cache[12] = _createElementVNode("div", { class: "header-faq heading-title" }, [
        _createElementVNode("h5", {
          class: "mb-2",
          style: {"font-size":"22px"}
        }, "Quick Questions are answered")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h5", _hoisted_6, [
                _createElementVNode("button", {
                  class: "btn btn-link collapsed",
                  "data-bs-toggle": "collapse",
                  "data-bs-target": "#collapseicon",
                  "aria-expanded": show3.value ? 'true' : 'false',
                  "aria-controls": "collapseicon",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (open3())),
                  style: {"font-size":"20px"}
                }, [
                  _createVNode(_component_vue_feather, { type: "help-circle" }),
                  _cache[4] || (_cache[4] = _createTextVNode(" Integrating WordPress with Your Website? "))
                ], 8, _hoisted_7)
              ])
            ]),
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass([show3.value ? 'block' : 'none', 'content'])
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("div", { class: "card-body" }, [
                _createElementVNode("p", null, " How you approach this process will depend on which web host you use. For example, a lot of hosting providers use cPanel, which includes an option to set up subdomains with just a few clicks. ")
              ], -1)
            ]), 2), [
              [_vShow, show3.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h5", _hoisted_10, [
                _createElementVNode("button", {
                  class: "btn btn-link collapsed",
                  "data-bs-toggle": "collapse",
                  "data-bs-target": "#collapseicon2",
                  "aria-expanded": show2.value ? 'true' : 'false',
                  "aria-controls": "collapseicon2",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (open2())),
                  style: {"font-size":"20px"}
                }, [
                  _createVNode(_component_vue_feather, { type: "help-circle" }),
                  _cache[6] || (_cache[6] = _createTextVNode(" WordPress Site Maintenance ? "))
                ], 8, _hoisted_11)
              ])
            ]),
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass([show2.value ? 'block' : 'none', 'content'])
            }, _cache[7] || (_cache[7] = [
              _createElementVNode("div", { class: "card-body" }, [
                _createElementVNode("p", null, " We’ve just published a detailed on how to backup your WordPress website, however, if you’re in a hurry, here’s a quick solution. ")
              ], -1)
            ]), 2), [
              [_vShow, show2.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("h5", _hoisted_14, [
                _createElementVNode("button", {
                  class: "btn btn-link collapsed",
                  "data-bs-toggle": "collapse",
                  "data-bs-target": "#collapseicon3",
                  "aria-expanded": show.value ? 'true' : 'false',
                  "aria-controls": "collapseicon2",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (open())),
                  style: {"font-size":"20px"}
                }, [
                  _createVNode(_component_vue_feather, { type: "help-circle" }),
                  _cache[8] || (_cache[8] = _createTextVNode("Meta Tags in WordPress ? "))
                ], 8, _hoisted_15)
              ])
            ]),
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass([show.value ? 'block' : 'none', 'content'])
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("div", { class: "card-body" }, [
                _createElementVNode("p", null, " Manually adding meta tags in WordPress is relatively simple. For this demo, we’ll use the example from the WordPress Codex. Imagine you’re Harriet Smith, a veterinarian who blogs about their animal stories on WordPress. ")
              ], -1)
            ]), 2), [
              [_vShow, show.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("h5", _hoisted_18, [
                _createElementVNode("button", {
                  class: "btn btn-link collapsed",
                  "data-bs-toggle": "collapse",
                  "data-bs-target": "#collapseicon4",
                  "aria-expanded": show1.value ? 'true' : 'false',
                  "aria-controls": "collapseicon2",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (open1())),
                  style: {"font-size":"20px"}
                }, [
                  _createVNode(_component_vue_feather, { type: "help-circle" }),
                  _cache[10] || (_cache[10] = _createTextVNode(" WordPress in Your Language ? "))
                ], 8, _hoisted_19)
              ])
            ]),
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass([show1.value ? 'block' : 'none', 'content'])
            }, _cache[11] || (_cache[11] = [
              _createElementVNode("div", { class: "card-body" }, [
                _createElementVNode("p", null, " As of version 4.0, you can have WordPress automatically install the language of your choice during the installation process. ")
              ], -1)
            ]), 2), [
              [_vShow, show1.value]
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})