import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-sm-12" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "card-content" }
const _hoisted_4 = { class: "card-header d-flex justify-content-between align-items-center" }
const _hoisted_5 = {
  class: "d-flex align-items-center",
  style: {"gap":"16px"}
}
const _hoisted_6 = {
  class: "table-responsive custom-scrollbar card-content",
  style: {"padding-right":"17px","padding-left":"17px"}
}
const _hoisted_7 = {
  key: 0,
  class: "table table-hover custom-table"
}
const _hoisted_8 = {
  key: 1,
  class: "no-result"
}
const _hoisted_9 = {
  key: 0,
  style: {"padding":"20px"}
}
const _hoisted_10 = {
  key: 1,
  class: "card-content"
}

import { ref, onMounted } from "vue";
import { fetchReVisitors, visitorDataList, VisitorResponse } from "@/api/reVisitor";
import scriptSetupMask from "@/components/mask/scriptSetup.vue";
import moment from "moment";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const dataList = ref<visitorDataList[]>([]);
const filterDates = ref<[Date, Date]>([new Date(), new Date()]);
const currentPage = ref(1);
const perPage = ref(30);
const totalRows = ref(0);
const pageCount = ref(0);

onMounted(() => {
  const endDate = new Date();
  const startDate = new Date(new Date().setDate(endDate.getDate() - 7));
  filterDates.value = [startDate, endDate];

  loadData();
});

function handlePageClick(event, page) {
  loadData(page);
}

function dateChange() {
  currentPage.value = 1;
  loadData();
}

const loadData = async (pageNo) => {
  try {
    const data: VisitorResponse = await fetchReVisitors(
      moment(filterDates.value[0]).format("YYYY-MM-DD"),
      moment(filterDates.value[1]).format("YYYY-MM-DD"),
      pageNo || currentPage.value,
      perPage.value
    );

    if (data && data.records) {
      dataList.value = data.records;
      totalRows.value = data.count;
      pageCount.value = data.pageCount;
    }
  } catch (error) {
    console.error(error);
  }
};

const downloadCSV = () => {
  const header = "REQUEST ID,VISITOR ID,IP ADDRESS,DATE\n";
  const csv =
    header +
    dataList.value
      .map(
        (item) =>
          `${item.requestId},${item.visitorId},${item.ip},${new Date(
            item.createdAt
          ).toLocaleString()}`
      )
      .join("\n");
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = "visitor_list.csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

return (_ctx: any,_cache: any) => {
  const _component_datepicker = _resolveComponent("datepicker")!
  const _component_b_pagination = _resolveComponent("b-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(scriptSetupMask),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("h5", {
            class: "card-title",
            style: {"font-weight":"600"}
          }, "Visitors Identification", -1)),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createVNode(_component_datepicker, {
                class: "digits",
                modelValue: filterDates.value,
                "onUpdate:modelValue": [
                  _cache[0] || (_cache[0] = ($event: any) => ((filterDates).value = $event)),
                  dateChange
                ],
                format: "yyyy-MM-dd",
                "multi-calendars": "",
                "enable-time-picker": false,
                range: ""
              }, null, 8, ["modelValue"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          (dataList.value.length > 0)
            ? (_openBlock(), _createElementBlock("table", _hoisted_7, [
                _cache[3] || (_cache[3] = _createElementVNode("thead", { class: "table" }, [
                  _createElementVNode("tr", null, [
                    _createElementVNode("th", null, "Request Id"),
                    _createElementVNode("th", null, "Visitor Id"),
                    _createElementVNode("th", null, "IP Address"),
                    _createElementVNode("th", null, "Browser"),
                    _createElementVNode("th", null, "Visited On")
                  ])
                ], -1)),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataList.value, (visitor, index) => {
                    return (_openBlock(), _createElementBlock("tr", { key: index }, [
                      _createElementVNode("td", null, _toDisplayString(visitor.requestId), 1),
                      _createElementVNode("td", null, _toDisplayString(visitor.visitorId), 1),
                      _createElementVNode("td", null, _toDisplayString(visitor.ip), 1),
                      _createElementVNode("td", null, _toDisplayString(visitor.browser), 1),
                      _createElementVNode("td", null, _toDisplayString(new Date(visitor.createdAt).toLocaleString()), 1)
                    ]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true),
          (dataList.value.length == 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[4] || (_cache[4] = [
                _createElementVNode("div", { style: {"font-weight":"500"} }, "No results were found", -1),
                _createElementVNode("span", null, "Try expanding the date range", -1)
              ])))
            : _createCommentVNode("", true)
        ]),
        (pageCount.value == 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9))
          : _createCommentVNode("", true),
        (pageCount.value > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_b_pagination, {
                modelValue: currentPage.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((currentPage).value = $event)),
                pills: "",
                align: "center",
                "total-rows": totalRows.value,
                "per-page": perPage.value,
                "first-text": "First",
                "prev-text": "Prev",
                "next-text": "Next",
                "last-text": "Last",
                onPageClick: handlePageClick
              }, null, 8, ["modelValue", "total-rows", "per-page"])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})