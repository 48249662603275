import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'indexChat',
  setup(__props) {

const searchChat = defineAsyncComponent(() => import("@/components/common/searchChat.vue"))


return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Chat",
      title: "Chat App"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(searchChat)),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col call-chat-body" }, [
          _createElementVNode("div", { class: "card" }, [
            _createElementVNode("div", { class: "card-body p-0" }, [
              _createElementVNode("div", { class: "row chat-box" })
            ])
          ])
        ], -1))
      ])
    ])
  ], 64))
}
}

})