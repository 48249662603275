import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { isAuthenticated } from './authService';

export function authGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const query = to.query
  if(query.token && query.token.length > 10){
    localStorage.setItem('token', (query.token || "") as string);
    if(query.source) sessionStorage.setItem('source', (query.source || "") as string);
    to.path && next(to.path);
  }

  if (isAuthenticated() || to.path === '/auth/login' || to.path === '/auth/register') {
    next();
  } else {
    next('/auth/login');
  }
}
