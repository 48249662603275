<template>
  <div class="privacy-policy-container">
    <h3 style="margin-top: 10px">Privacy Policy</h3>
    <br />
  </div>
  <div class="d-flex justify-content-center">
    <div class="card col-xl-10">
      <div class="card-body col-xl-12">
        <p class="text-content">
          Thank you for visiting our website. This privacy policy describes how
          we collect, use, protect, and disclose information about you when you
          visit our website or use our services.
        </p>
        <br />
        <p class="text-content">
          <strong> Information We Collect</strong> <br />
          We may collect personal information such as your name, email address,
          phone number, and other contact information when you fill out forms on
          our website, subscribe to our newsletter, or interact with our
          content.

          <br />
          We also automatically collect certain information about your device
          and browsing activity using cookies, web beacons, and similar
          technologies. This information may include your IP address, browser
          type, operating system, and pages visited on our website.
        </p class="text-content">
        <br />
        <div class="text-content">
        <strong>How We Use Your Information </strong> <br />We use the
        information we collect to: <br />
        <ul style="list-style-type: disc; margin-left: 35px;" class="text-content">
          <li>Provide and improve our services</li>
          <li>
            Communicate with you, including responding to your inquiries and
            providing updates
          </li>
          <li>Send you marketing and promotional materials</li>
          <li>Analyze trends and user behavior on our website</li>
        </ul>
    </div>
    <p class="text-content">
        <strong>Information Sharing</strong><br />
        We may share your information with third-party service providers that
        help us operate our website, manage our marketing campaigns, or deliver
        services to you. We may also share information in response to legal
        requests or to protect our rights and interests. <br />
    </p>
    <p class="text-content">
        <strong>Data Security</strong> <br />
        We take reasonable measures to protect your personal information from
        unauthorized access, use, or disclosure. However, no method of
        transmission over the internet or electronic storage is 100% secure, and
        we cannot guarantee absolute security. <br />
    </p>
    <p class="text-content">
        <strong>Your Choices</strong> <br />
        You can opt out of receiving marketing communications from us by
        following the unsubscribe instructions in our emails or contacting us
        directly. You can also disable cookies in your browser settings,
        although this may affect your experience on our website. <br />
    </p>
    <p class="text-content">
        <strong>Changes to This Privacy Policy</strong> <br />
        We may update this privacy policy from time to time to reflect changes
        in our practices or legal requirements. We will notify you of any
        significant changes by posting the new policy on our website or sending
        you a notification. 
    </p>
    <p class="text-content">
        <br /><strong>Contact Us</strong> <br />
        If you have any questions or concerns about our privacy policy, please
        contact us at <span class="text-primary">business@unifers.ai</span>.
    </p>
      </div>
    </div>
  </div>
</template>
<style>
.privacy-policy-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.text-content {
  font-size: 16px;
}
/* .card {
  width: 100%;
  max-width: 600px; 
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.card-body {
  text-align: justify; / */
/* } */
</style>
