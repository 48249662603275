import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container-fluid d-flex align-items-center justify-content-center min-vh-100" }
const _hoisted_2 = { class: "row w-100" }
const _hoisted_3 = { class: "col-12 d-flex justify-content-center" }
const _hoisted_4 = {
  class: "card p-4 shadow-lg",
  style: {"max-width":"400px","width":"100%"}
}
const _hoisted_5 = {
  key: 0,
  class: "success-message text-center"
}
const _hoisted_6 = { class: "form-group mb-3" }
const _hoisted_7 = {
  key: 0,
  class: "invalid-feedback"
}
const _hoisted_8 = {
  key: 0,
  class: "error text-center mt-3"
}
const _hoisted_9 = { class: "text-center mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[7] || (_cache[7] = _createElementVNode("h4", { class: "text-center mb-4" }, "Forgot Password", -1)),
          (_ctx.showAlert.visible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.showAlert.message), 1))
            : _createCommentVNode("", true),
          _createElementVNode("form", {
            onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForgotPassword && _ctx.submitForgotPassword(...args)), ["prevent"])),
            novalidate: ""
          }, [
            _createElementVNode("div", _hoisted_6, [
              _cache[3] || (_cache[3] = _createElementVNode("label", {
                for: "email",
                class: "form-label"
              }, "Email:", -1)),
              _withDirectives(_createElementVNode("input", {
                type: "email",
                id: "email",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                class: _normalizeClass(["form-control", {'is-invalid': _ctx.emailError}]),
                onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateEmail && _ctx.validateEmail(...args)))
              }, null, 34), [
                [_vModelText, _ctx.email]
              ]),
              (_ctx.emailError)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.emailErrorMessage), 1))
                : _createCommentVNode("", true)
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("button", {
              type: "submit",
              class: "btn btn-primary w-100"
            }, "Send Reset Email", -1)),
            (_ctx.errorMessage)
              ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.errorMessage), 1))
              : _createCommentVNode("", true)
          ], 32),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", null, [
              _cache[6] || (_cache[6] = _createTextVNode(" Already have an account? ")),
              _createVNode(_component_router_link, {
                to: "/auth/login",
                class: "sign-in-link"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Sign in")
                ])),
                _: 1
              })
            ])
          ])
        ])
      ])
    ])
  ]))
}