import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body whishlist-main" }
const _hoisted_6 = { class: "row" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'indexWishlist',
  setup(__props) {

const wishList = defineAsyncComponent(() => import("@/components/ecommerce/whishlist/wishList.vue"))

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Ecommerce",
      title: "Wishlist"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "card-header pb-0" }, [
              _createElementVNode("h5", null, "Wishlist")
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_unref(wishList))
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})