import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'indexInputs',
  setup(__props) {

const basicForm = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/basicForm.vue"))
const edgesInput = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/edgesInput.vue"))
const faltInput = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/faltInput.vue"))
const raiseInput = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/raiseInput.vue"))
const solidInput = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/solidInput.vue"))
const inputSizing = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/inputSizing.vue"))
const customControls = defineAsyncComponent(() => import("@/components/forms/foremcontrols/baseinputs/customControls.vue"))

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Form Controls",
      title: "Base Inputs"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(basicForm)),
        _createVNode(_unref(edgesInput)),
        _createVNode(_unref(faltInput)),
        _createVNode(_unref(raiseInput)),
        _createVNode(_unref(solidInput)),
        _createVNode(_unref(inputSizing)),
        _createVNode(_unref(customControls))
      ])
    ])
  ], 64))
}
}

})