import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, Transition as _Transition, withCtx as _withCtx, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-body-wrapper" }
const _hoisted_2 = { class: "page-body" }

import { defineAsyncComponent } from "vue";
import { layoutClasses } from "@/constants/layout";
import { useMenuStore } from "@/store/menu";
import config from "@/data/layout.json";
import { uselayoutStore } from "@/store/layout";
import { useProductsStore } from "@/store/products";
import footerView from "./footerView.vue";
import tapTop from "./tapTop.vue";
import Header from "@/components/header/indexView.vue";
import Sidebar from "@/components/sidebar/indexView.vue";

import { ref, onMounted, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";


export default /*@__PURE__*/_defineComponent({
  __name: 'BodyView',
  setup(__props) {

const layoutobj = ref<any>({});
const sidebar_toggle_var = ref<boolean>(false);
let store = useMenuStore();
const storeLayout = uselayoutStore();
const router = useRouter();
const product = useProductsStore();
store.togglesidebar;
const { layout: layout } = storeToRefs(storeLayout);
const width = window.innerWidth;
const menuItems = store.data;
const size = ref(0);
const setSize = ref(0);
const screenWidth = ref<number>(0);
const display = ref<boolean>(false);

function sidebar_toggle(value: boolean) {
  sidebar_toggle_var.value = !value;
}
onMounted(() => {
  window.addEventListener("resize", handleScroll);
  layoutobj.value = storeLayout.layout.settings.sidebar_setting;
  localStorage.setItem("cartItem", JSON.stringify(product.cart));
});
function handleScroll() {
  if (window.innerWidth <= 1199) {
    display.value = true;
  } else {
    display.value = false;
  }
}
watch(
  () => layout,
  () => {
    layoutobj.value = storeLayout.layout.settings.sidebar_setting;
  },
  { deep: true }
);
watch(
  () => "router",
  () => {
    if (
      window.innerWidth < 991 &&
      storeLayout.layout.settings.layout === "Horizontal"
    ) {
      const newlayout = JSON.parse(
        JSON.stringify(layoutobj).replace(
          "horizontal-wrapper",
          "compact-wrapper"
        )
      );
      layoutobj.value = JSON.parse(JSON.stringify(newlayout))[
        storeLayout.layout.settings.layout
      ];
    } else {
      layoutobj.value = JSON.parse(JSON.stringify(layoutobj))[
        storeLayout.layout.settings.layout
      ];
    }
  }
);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(tapTop),
    _createTextVNode(" " + _toDisplayString(display.value) + " ", 1),
    _createElementVNode("div", {
      class: _normalizeClass(["page-wrapper", display.value ? 'compact-wrapper' : layoutobj.value])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["page-header", { close_icon: !_unref(store).togglesidebar }])
      }, [
        _createVNode(Header, { onClicked: sidebar_toggle })
      ], 2),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["sidebar-wrapper", [{ close_icon: !_unref(store).togglesidebar }]])
        }, [
          _createVNode(Sidebar, { onClicked: sidebar_toggle })
        ], 2),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_Transition, {
            name: "fadeIn",
            "enter-active-class": "animated fadeIn"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          })
        ]),
        _createVNode(footerView)
      ])
    ], 2)
  ], 64))
}
}

})