import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "container-fluid default-dash" }
const _hoisted_3 = { class: "row justify-content-center" }
const _hoisted_4 = { class: "col-xl-4 d-flex justify-content-center" }

import { defineAsyncComponent } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'workspace',
  setup(__props) {

const workspacetxt = defineAsyncComponent(
  () => import("@/pages/workspace/workspacetxt.vue")
);

const brands = defineAsyncComponent(
  () => import("@/pages/workspace/brands.vue")
);
const createbtn = defineAsyncComponent(
  () => import("@/pages/workspace/createbtn.vue")
);
const addTeam = defineAsyncComponent(
  () => import("@/pages/workspace/addTeam.vue")
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(workspacetxt)),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_unref(createbtn)),
          _createVNode(_unref(addTeam))
        ]),
        _createVNode(_unref(brands))
      ])
    ])
  ]))
}
}

})