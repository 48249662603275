<template>
    <div class="container-fluid default-dash">
      <div class="row">
        <totalVisitor v-show="showVisitor" :visitorData="visitorData" />
        <totalIdentified v-show="showDevice" :identificationData="identificationData" />
         <!-- <companyView />  -->
        <visitorGraph v-show="showVisitor" :visitorData="visitorData.dayWise" />
        <identificationGraph v-show="showDevice" :identificationData="identificationData.dayWise" />
      </div>
    </div>
  </template>
  
  <script lang="ts" setup>
  import { defineAsyncComponent, ref, onMounted } from 'vue';
  import { fetchDashboardData } from '@/api/dashboard';
  
  const totalVisitor = defineAsyncComponent(() => import("@/components/widget/general/totalVisitor.vue"));
  const totalIdentified = defineAsyncComponent(() => import("@/components/widget/general/totalIdentified.vue"));
  //const companyView = defineAsyncComponent(() => import("@/components/dashbords/default/companyView.vue"));
  const visitorGraph = defineAsyncComponent(() => import("@/components/dashbords/default/visitorGraph.vue"));
  const identificationGraph = defineAsyncComponent(() => import("@/components/dashbords/default/identificationGraph.vue"));
  
  const showVisitor = ref(true)
  const showDevice = ref(true)

  const visitorData = ref<{
    total: number;
    emailTotal: number;
    phoneTotal: number;
    totalInMonth: number;
    dayWise: Array<{ count: string; date: string }>;
  }>({
    total: 0,
    emailTotal: 0,
    phoneTotal: 0,
    totalInMonth: 0,
    dayWise: []
  });
  
  const identificationData = ref<{
    total: number;
    totalInMonth: number;
    totalReVisitor: number;
    totalReVisitorInMonth: number;
    dayWise: Array<{ count: string; date: string }>;
  }>({
    total: 0,
    totalInMonth: 0,
    totalReVisitor: 0,
    totalReVisitorInMonth: 0,
    dayWise: []
  });
  
  async function fetchDashboardDataAsync() {
    try {
      const { visitor, identification } = await fetchDashboardData();
      visitorData.value = visitor;
      identificationData.value = identification;
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  }
  
  onMounted(() => {
    let source = sessionStorage.getItem('source')
    if(source){
      if(source == 'device') showVisitor.value = false
      if(source == 'visitor') showDevice.value = false
    }
    fetchDashboardDataAsync();
  });
  </script>
  
