import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "user-profile" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-xl-3 col-md-5 xl-35 box-col-35" }
const _hoisted_5 = { class: "default-according style-1 job-accordion" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-xl-9 col-md-7 xl-65 box-col-65" }
const _hoisted_8 = { class: "row" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'indexProfile',
  setup(__props) {

const profileHeader = defineAsyncComponent(() => import("@/components/user/profile/profileHeader.vue"))
const defaultAccording = defineAsyncComponent(() => import("@/components/common/defaultAccording.vue"))
const profilePost = defineAsyncComponent(() => import("@/components/user/profile/profilePost.vue"))

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Users",
      title: "User Profile"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_unref(profileHeader)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_unref(defaultAccording))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_unref(profilePost))
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})