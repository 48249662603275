<template>
  <div class="container-fluid">
    <div class="row developer-cards">
      <div class="col-md-12 developer-Tab">
        <div class="card">
          <div class="row">
            <div class="col-md-3 p-0 d-flex">
              <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist">
                <li v-show="showVisitor" class="nav-item">
                  <a
                    class="nav-link"
                    :class="{ active: activeTab === 'visitor' }"
                    id="top-home-tab"
                    @click="activeTab = 'visitor'"
                    data-bs-toggle="tab"
                    href="#top-home"
                    role="tab"
                    aria-controls="top-home"
                    aria-selected="true"
                  >
                    Visitor
                    <span class="indicator"></span>
                  </a>
                </li>
                <li v-show="showDevice" class="nav-item">
                  <a
                    class="nav-link"
                    :class="{ active: activeTab === 'identification' }"
                    id="profile-top-tab"
                    @click="activeTab = 'identification'"
                    data-bs-toggle="tab"
                    href="#top-profile"
                    role="tab"
                    aria-controls="top-profile"
                    aria-selected="false"
                  >
                    Identification
                    <span class="indicator"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="tab-content">
            <div
              class="tab-pane fade"
              :class="{ 'show active': activeTab === 'visitor' }"
              id="top-home"
              role="tabpanel"
              aria-labelledby="top-home-tab"
            >
              <div class="tab-content-inner">
                <visitor />
              </div>
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'show active': activeTab === 'identification' }"
              id="top-profile"
              role="tabpanel"
              aria-labelledby="profile-top-tab"
            >
              <div class="tab-content-inner">
                <reVisitor />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { defineAsyncComponent } from "vue";

const visitor = defineAsyncComponent(() => import("./visitor.vue"));
const reVisitor = defineAsyncComponent(() => import("./reVisitor.vue"));
const activeTab = ref("visitor");
const showVisitor = ref(true)
const showDevice = ref(true)
let source = sessionStorage.getItem('source')

if(source){
  if(source == 'device'){
    activeTab.value = "identification"
    showVisitor.value = false
  }
  if(source == 'visitor'){
    activeTab.value = "visitor"
    showDevice.value = false
  }
}

</script>

<style scoped>
.developer-Tab {
  padding: 15px;
}

.developer-cards .developer-Tab .border-tab.nav-tabs {
  margin-bottom: 0px;
}

.card {
  padding: 20px;
}

.nav-tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.nav-link {
  position: relative;
  padding-bottom: 10px;
}

.nav-link.active .indicator {
  background-color: #007bff;
  width: 100%;
  left: 0;
}

.indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.3s, width 0.3s, left 0.3s;
}

.nav-item {
  position: relative;
}

.nav-link.active .indicator {
  background-color: #007bff;
}

.nav-link.active {
  color: #007bff;
}

.tab-content-inner {
  padding: 20px;
  border-top: 1px solid #ddd;
}
</style>
