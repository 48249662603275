import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mask-overlay"
}
const _hoisted_2 = { class: "mask-content" }

import { computed } from "vue";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'scriptSetup',
  setup(__props) {

const store = useStore();
const activeWorkspace = computed(() => store.state.activeWorkspace);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (activeWorkspace.value && activeWorkspace.value.isActivated == false)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[1] || (_cache[1] = _createElementVNode("h5", null, "Script is not installed", -1)),
          _createVNode(_component_router_link, { to: "/setup-guide" }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("button", {
                class: "btn-primary btn-round",
                type: "button",
                style: {"padding":"7px 20px","color":"white","border-radius":"5px"}
              }, " Goto Setup Guide ", -1)
            ])),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})