import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = { class: "row m-0" }
const _hoisted_3 = { class: "col-12 p-0" }
const _hoisted_4 = { class: "login-card" }
const _hoisted_5 = { class: "login-main" }
const _hoisted_6 = {
  key: 0,
  class: "text-center text-danger"
}
const _hoisted_7 = {
  key: 1,
  class: "success-message text-center mb-3"
}
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = { class: "row g-2" }
const _hoisted_10 = { class: "col-6" }
const _hoisted_11 = {
  key: 0,
  class: "invalid-feedback text-danger"
}
const _hoisted_12 = { class: "col-6" }
const _hoisted_13 = {
  key: 0,
  class: "invalid-feedback text-danger"
}
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = {
  key: 0,
  class: "invalid-feedback text-danger"
}
const _hoisted_16 = { class: "form-group" }
const _hoisted_17 = {
  key: 0,
  class: "invalid-feedback text-danger"
}
const _hoisted_18 = { class: "form-group" }
const _hoisted_19 = {
  key: 0,
  class: "invalid-feedback text-danger"
}
const _hoisted_20 = { class: "mt-4 mb-0 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("form", {
                class: "needs-validation",
                novalidate: "",
                onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
              }, [
                _cache[12] || (_cache[12] = _createElementVNode("h4", { class: "text-center" }, "Create your account", -1)),
                _cache[13] || (_cache[13] = _createElementVNode("p", { class: "text-center" }, " Enter your personal details to create account ", -1)),
                (_ctx.serverError)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.serverError), 1))
                  : _createCommentVNode("", true),
                (_ctx.successMessage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.successMessage), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_8, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", { class: "col-form-label pt-0" }, "Your Name", -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _withDirectives(_createElementVNode("input", {
                        class: _normalizeClass(["form-control", {
                          'is-invalid': _ctx.formSubmitted && _ctx.firstnameError,
                          'is-valid': _ctx.formSubmitted && !_ctx.firstnameError,
                        }]),
                        type: "text",
                        name: "firstname",
                        autocomplete: "given-name",
                        required: "",
                        placeholder: "First name",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.firstname) = $event))
                      }, null, 2), [
                        [_vModelText, _ctx.firstname]
                      ]),
                      (_ctx.formSubmitted && _ctx.firstnameError)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.firstnameErrorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _withDirectives(_createElementVNode("input", {
                        class: _normalizeClass(["form-control", {
                          'is-invalid': _ctx.formSubmitted && _ctx.lastnameError,
                          'is-valid': _ctx.formSubmitted && !_ctx.lastnameError,
                        }]),
                        type: "text",
                        name: "lastname",
                        autocomplete: "family-name",
                        required: "",
                        placeholder: "Last name",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.lastname) = $event))
                      }, null, 2), [
                        [_vModelText, _ctx.lastname]
                      ]),
                      (_ctx.formSubmitted && _ctx.lastnameError)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.lastnameErrorMessage), 1))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _cache[7] || (_cache[7] = _createElementVNode("label", { class: "col-form-label" }, "Email Address", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: _normalizeClass(["form-control", {
                      'is-invalid': _ctx.formSubmitted && _ctx.emailError,
                      'is-valid': _ctx.formSubmitted && !_ctx.emailError,
                    }]),
                    type: "email",
                    name: "email",
                    autocomplete: "email",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.email) = $event)),
                    placeholder: "Enter your email",
                    required: ""
                  }, null, 2), [
                    [_vModelText, _ctx.email]
                  ]),
                  (_ctx.formSubmitted && _ctx.emailError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.emailErrorMessage), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _cache[8] || (_cache[8] = _createElementVNode("label", { class: "col-form-label" }, "Phone Number", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: _normalizeClass(["form-control", {
                      'is-invalid': _ctx.formSubmitted && _ctx.phoneNumberError,
                      'is-valid': _ctx.formSubmitted && !_ctx.phoneNumberError,
                    }]),
                    type: "text",
                    name: "phone",
                    autocomplete: "tel",
                    required: "",
                    placeholder: "Enter your phone number",
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phoneNumber) = $event))
                  }, null, 2), [
                    [_vModelText, _ctx.phoneNumber]
                  ]),
                  (_ctx.formSubmitted && _ctx.phoneNumberError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.phoneNumberErrorMessage), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _cache[9] || (_cache[9] = _createElementVNode("label", { class: "col-form-label" }, "Password", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: _normalizeClass(["form-control", {
                      'is-invalid': _ctx.formSubmitted && _ctx.passwordError,
                      'is-valid': _ctx.formSubmitted && !_ctx.passwordError,
                    }]),
                    type: "password",
                    name: "password",
                    autocomplete: "new-password",
                    required: "",
                    placeholder: "Enter your password",
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.password) = $event))
                  }, null, 2), [
                    [_vModelText, _ctx.password]
                  ]),
                  (_ctx.formSubmitted && _ctx.passwordError)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.passwordErrorMessage), 1))
                    : _createCommentVNode("", true)
                ]),
                _cache[14] || (_cache[14] = _createElementVNode("button", {
                  class: "btn btn-primary btn-block w-100 mt-3",
                  type: "submit"
                }, " Create Account ", -1)),
                _createElementVNode("p", _hoisted_20, [
                  _cache[11] || (_cache[11] = _createTextVNode(" Already have an account? ")),
                  _createVNode(_component_router_link, {
                    class: "ms-2",
                    to: "/auth/login"
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("Sign in")
                    ])),
                    _: 1
                  })
                ])
              ], 32)
            ])
          ])
        ])
      ])
    ])
  ]))
}