import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/logo/uni.png'


const _hoisted_1 = { class: "col-sm-12 center-input mb-3" }
const _hoisted_2 = { class: "col-sm-12 center-input mb-3" }
const _hoisted_3 = { class: "col-sm-12 center-input mb-3" }
const _hoisted_4 = {
  key: 0,
  class: "col-sm-12 center-input mb-3"
}
const _hoisted_5 = { class: "col-sm-12 center-input mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[6] || (_cache[6] = _createElementVNode("img", {
      class: "center-image mb-4",
      src: _imports_0,
      alt: ""
    }, null, -1)),
    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "line-content" }, [
      _createElementVNode("p", null, "Opt-Out Form")
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
        type: "email",
        placeholder: "Email Address",
        class: "input-field",
        required: ""
      }, null, 512), [
        [_vModelText, _ctx.email]
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.zipcode) = $event)),
        type: "text",
        placeholder: "Zipcode",
        class: "input-field",
        required: ""
      }, null, 512), [
        [_vModelText, _ctx.zipcode]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        type: "checkbox",
        id: "terms",
        class: "checkbox-field",
        onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleAdditionalField && _ctx.toggleAdditionalField(...args))),
        required: ""
      }, null, 32),
      _cache[5] || (_cache[5] = _createElementVNode("p", { style: {"font-weight":"500"} }, " I am an authorized agent submitting the request on behalf of the user.. ", -1))
    ]),
    (_ctx.showAdditionalField)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.agentEmail) = $event)),
            type: "email",
            placeholder: "Authorized Agent Email Address",
            class: "input-field",
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.agentEmail]
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handleButtonClick && _ctx.handleButtonClick(...args)))
      }, "Submit")
    ])
  ]))
}