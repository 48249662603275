import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "error-wrapper maintenance-bg" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12" }

import maintenanceView from "@/svg/maintenanceView.vue"

export default /*@__PURE__*/_defineComponent({
  __name: 'maintenanceView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(maintenanceView),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "maintenance-heading" }, [
            _createElementVNode("h2", { class: "headline" }, "MAINTENANCE")
          ], -1)),
          _cache[2] || (_cache[2] = _createElementVNode("h4", { class: "sub-content" }, [
            _createTextVNode("Our Site is Currently under maintenance We will be back Shortly"),
            _createElementVNode("br"),
            _createTextVNode(" Thank You For Patience")
          ], -1)),
          _createElementVNode("div", null, [
            _createVNode(_component_router_link, {
              class: "btn btn-primary",
              to: "/"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("BACK TO HOME PAGE")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}
}

})