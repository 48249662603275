import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col call-chat-body" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body p-0" }
const _hoisted_6 = { class: "row chat-box" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'indexVideo',
  setup(__props) {

const searchChat = defineAsyncComponent(() => import("@/components/common/searchChat.vue"))
const chatRight = defineAsyncComponent(() => import("@/components/chat/videochat/chatRight.vue"))

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "Chat",
      title: "Video Chat"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_unref(searchChat)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_unref(chatRight))
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})