import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container invoice" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "col-sm-12 text-center mt-3" }

import { defineAsyncComponent } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'indexInvoice',
  setup(__props) {

const invoiceHeader = defineAsyncComponent(() => import("@/components/ecommerce/invoice/invoiceHeader.vue"))
const invoiceProfile = defineAsyncComponent(() => import("@/components/ecommerce/invoice/invoiceProfile.vue"))
const invoiceTable = defineAsyncComponent(() => import("@/components/ecommerce/invoice/invoiceTable.vue"))
function printWindow() {
    window.print();
}

return (_ctx: any,_cache: any) => {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Breadcrumbs, {
      main: "ECommerce",
      title: "Invoice"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _createVNode(_unref(invoiceHeader)),
                _createVNode(_unref(invoiceProfile)),
                _createVNode(_unref(invoiceTable))
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("button", {
                  class: "btn btn btn-primary me-2",
                  type: "button",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (printWindow()))
                }, "Print"),
                _cache[1] || (_cache[1] = _createElementVNode("button", {
                  class: "btn btn-secondary",
                  type: "button"
                }, "Cancel", -1))
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})