import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "login-card" }
const _hoisted_5 = { class: "login-main" }
const _hoisted_6 = {
  key: 0,
  class: "success-message text-center mb-3"
}
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "form-input position-relative" }
const _hoisted_9 = {
  key: 0,
  class: "invalid-tooltip"
}
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = {
  key: 0,
  class: "invalid-tooltip"
}
const _hoisted_12 = {
  key: 1,
  class: "form-group mb-0"
}
const _hoisted_13 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_14 = { class: "mt-4 mb-0 text-center" }

import { ref } from 'vue';
import { changePassword } from '@/api/changePassword';
import { useRouter } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'resetPassword',
  setup(__props) {

const formSubmitted = ref<boolean>(false);
const errors = ref<Array<{ message: string }>>([]);
const password = ref<string>('');
const passwordError = ref<boolean>(false);
const confirmPassword = ref<string>('');
const confirmPasswordError = ref<boolean>(false);
const successMessage = ref<string | null>(null);

const router = useRouter();

const handleError = (error: unknown): string => {
  if (error && typeof error === 'object' && 'response' in error) {
    return (error as any).response?.data?.message || 'An error occurred';
  }
  return 'An unexpected error occurred';
};

const onCustomStyleSubmit = async () => {
  formSubmitted.value = true;
  errors.value = [];
  successMessage.value = null;

  if (password.value.length < 7) {
    passwordError.value = true;
    errors.value.push({ message: 'Please enter a password.' });
  } else {
    passwordError.value = false;
    errors.value.push({ message: '' });
  }

  if (confirmPassword.value !== password.value) {
    confirmPasswordError.value = true;
    errors.value.push({ message: 'Passwords do not match.' });
  } else {
    confirmPasswordError.value = false;
    errors.value.push({ message: '' });
  }

  if (!passwordError.value && !confirmPasswordError.value) {
    try {
      await changePassword({ newPassword: password.value });
      successMessage.value = 'Password changed successfully.';
      setTimeout(() => {
        router.push('/auth/login');
      }, 2000); // Delay for 2 seconds before redirecting
    } catch (error) {
      errors.value.push({ message: handleError(error) });
    }
  }
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("form", {
                class: "theme-form",
                onSubmit: _withModifiers(onCustomStyleSubmit, ["prevent"]),
                novalidate: ""
              }, [
                _cache[6] || (_cache[6] = _createElementVNode("h4", { class: "text-center" }, "Reset Your Password", -1)),
                _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-center" }, "Create Your Password", -1)),
                (successMessage.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(successMessage.value), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_7, [
                  _cache[2] || (_cache[2] = _createElementVNode("label", { class: "col-form-label" }, "New Password", -1)),
                  _createElementVNode("div", _hoisted_8, [
                    _withDirectives(_createElementVNode("input", {
                      class: _normalizeClass(["form-control", formSubmitted.value ? (passwordError.value ? 'is-invalid' : 'is-valid') : '']),
                      type: "password",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((password).value = $event)),
                      placeholder: "*********",
                      required: ""
                    }, null, 2), [
                      [_vModelText, password.value]
                    ]),
                    (passwordError.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(errors.value[0]?.message), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[3] || (_cache[3] = _createElementVNode("label", { class: "col-form-label" }, "Retype Password", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: _normalizeClass(["form-control", formSubmitted.value ? (confirmPasswordError.value ? 'is-invalid' : 'is-valid') : '']),
                    type: "password",
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((confirmPassword).value = $event)),
                    placeholder: "*********",
                    required: ""
                  }, null, 2), [
                    [_vModelText, confirmPassword.value]
                  ]),
                  (confirmPasswordError.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(errors.value[1]?.message), 1))
                    : _createCommentVNode("", true)
                ]),
                (password.value && confirmPassword.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      (password.value !== confirmPassword.value)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_13, " Passwords do not match. "))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "form-group mb-0" }, [
                  _createElementVNode("button", {
                    class: "btn btn-primary btn-block w-100 mt-3",
                    type: "submit"
                  }, " Done ")
                ], -1)),
                _createElementVNode("p", _hoisted_14, [
                  _cache[5] || (_cache[5] = _createTextVNode(" Already have a password? ")),
                  _createVNode(_component_router_link, {
                    class: "ms-2",
                    to: "/auth/login"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("Sign in")
                    ])),
                    _: 1
                  })
                ])
              ], 32)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})