import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import store from './store/index'
import { useScriptStore } from './api/store/store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';

import { BootstrapVue3 } from 'bootstrap-vue-3';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';

import 'bootstrap/dist/js/bootstrap.bundle'
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/scss/app.scss"
import 'vue3-openlayers/dist/vue3-openlayers.css'
import '@vuepic/vue-datepicker/dist/main.css'
import "vue-multiselect/dist/vue-multiselect.css"
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import 'vue-rate/dist/vue-rate.css'
import 'vue3-tour/dist/vue3-tour.css'
import "v3-tour/dist/vue-tour.css";
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';
import '@globalhive/vuejs-tour/dist/style.css';
import '@/styles/css/custom.css';

import VueFeather from "vue-feather";
import Breadcrumbs from './layout/breadCrumbs.vue';
import VueApexCharts from "vue3-apexcharts";
import vueChartist from "vue-chartist"
import OpenLayersMap from 'vue3-openlayers'
import VueNumber from "vue-number-animation";
import VCalendar from 'v-calendar';
import Lightbox from 'vue-easy-lightbox'
import VueSweetalert2 from 'vue-sweetalert2';
import Toaster from "@meforma/vue-toaster";
import Datepicker from '@vuepic/vue-datepicker';
import Multiselect from 'vue-multiselect'
import AosVue from "aos-vue";
import { VueMasonryPlugin } from 'vue-masonry';
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import Notifications from '@kyvg/vue3-notification'
import rate from 'vue-rate'
import Vue3Tour from 'vue3-tour'
import VueTour from 'v3-tour'
import { quillEditor } from "vue3-quill";
import SimpleTypeahead from 'vue3-simple-typeahead';
import KnobControl from 'vue-knob-control'
import VueJsTour from '@globalhive/vuejs-tour';

import English from "./locales/en.json"
import German from "./locales/ge.json"
import Russian from "./locales/ru.json"
import Arabic from "./locales/ar.json"
import Español from "./locales/es.json"
import Deutsch from "./locales/de.json"
import Français from "./locales/fr.json"
import Português from "./locales/pt.json"
import 简体中文 from "./locales/cn.json"
import لعربية from "./locales/ae.json"

import { createI18n } from 'vue-i18n'

const i18n = createI18n({
    legacy: false,
    locale: 'ja',
    messages: {
        English: English,
        German: German,
        Russian: Russian,
        Arabic: Arabic,
        Español: Español,
        Deutsch: Deutsch,
        Français: Français,
        Português: Português,
        简体中文: 简体中文,
        لعربية: لعربية,
    }
})

const app = createApp(App);

// Error handler
app.config.errorHandler = function (err, vm, info) {
  console.error('Vue Error:', err, info);
};

app.use(createPinia())
  .use(BootstrapVue3)
   .use(router)
   .use(store)
   .use(VueApexCharts)
   .use(OpenLayersMap)
   .use(vueChartist)
   .use(VueNumber)
   .use(Lightbox)
   .use(Toaster)
   .use(AosVue)
   .use(rate)
   .use(i18n)
   .use(Vue3Tour)
   .use(VueTour)
   .use(VueJsTour)
   .use(quillEditor)
   .use(Notifications)
   .use(KnobControl)
   .use(VueMasonryPlugin)
   .use(PerfectScrollbar)
   .use(SimpleTypeahead)
   .use(VCalendar, {})
   .provide('Swal', VueSweetalert2)
   .component(VueFeather.name || "", VueFeather)
   .component('Breadcrumbs', Breadcrumbs)
   .component('Datepicker', Datepicker)
   .component('multiselect', Multiselect)
   .mount('#app');
