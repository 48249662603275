import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/images/login/3.jpg'
import _imports_1 from '@/assets/images/logo/login.png'


const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-xl-7 p-0" }
const _hoisted_4 = { class: "login-card" }
const _hoisted_5 = { class: "logo text-center" }
const _hoisted_6 = { class: "login-main" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = {
  key: 0,
  class: "invalid-tooltip"
}
const _hoisted_9 = { class: "form-group" }
const _hoisted_10 = { class: "form-input position-relative" }
const _hoisted_11 = {
  key: 0,
  class: "invalid-tooltip"
}
const _hoisted_12 = { class: "form-group mb-0" }
const _hoisted_13 = { class: "link" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "login-social" }
const _hoisted_16 = {
  href: "https://www.linkedin.com",
  target: "_blank"
}
const _hoisted_17 = {
  href: "https://twitter.com",
  target: "_blank"
}
const _hoisted_18 = {
  href: "https://www.facebook.com",
  target: "_blank"
}
const _hoisted_19 = {
  href: "https://www.instagram.com",
  target: "_blank"
}
const _hoisted_20 = { class: "mt-4 mb-0 text-center" }

import { ref } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'loginTooltip',
  setup(__props) {

const email = ref<string>("")
const formSubmitted = ref<boolean>(false)
const emailError = ref<boolean>(false)
const emailFieldClasses = ref<string>("")
const errors = ref<any>([])
const password = ref<string>("")
const passwordError = ref<boolean>(false)

function onCustomStyleSubmit() {
    formSubmitted.value = true;
    errors.value = [];
    if (email.value.length < 10 || email.value.search("@") == -1) {
        emailError.value = true;
        errors.value.push({ 'message': 'Please enter proper email.' });
    } else {
        emailError.value = false;
        errors.value.push({});

    }
    if (password.value.length < 7) {
        passwordError.value = true;
        errors.value.push({ 'message': 'Please enter password.' });
    } else {
        passwordError.value = false;
        errors.value.push({});
    }
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_vue_feather = _resolveComponent("vue-feather")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "col-xl-5 b-center bg-size",
        style: _normalizeStyle({ backgroundImage: 'url(' + require('@/assets/images/login/3.jpg') + ')' })
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("img", {
          class: "bg-img-cover bg-center",
          style: {"display":"none"},
          src: _imports_0,
          alt: "looginpage"
        }, null, -1)
      ]), 4),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("div", null, [
              _createElementVNode("a", _hoisted_5, [
                _createVNode(_component_router_link, { to: "/" }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createElementVNode("img", {
                      class: "img-fluid for-light",
                      src: _imports_1,
                      alt: "looginpage"
                    }, null, -1)
                  ])),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("form", {
                class: "theme-form",
                novalidate: "",
                onSubmit: _withModifiers(onCustomStyleSubmit, ["prevent"])
              }, [
                _cache[12] || (_cache[12] = _createElementVNode("h4", { class: "text-center" }, "Sign in to account", -1)),
                _cache[13] || (_cache[13] = _createElementVNode("p", { class: "text-center" }, "Enter your email & password to login", -1)),
                _createElementVNode("div", _hoisted_7, [
                  _cache[4] || (_cache[4] = _createElementVNode("label", { class: "col-form-label" }, "Email Address", -1)),
                  _withDirectives(_createElementVNode("input", {
                    class: _normalizeClass(["form-control", formSubmitted.value ? emailError.value ? 'is-invalid' : 'is-valid' : '']),
                    type: "email",
                    id: "email",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
                    placeholder: "Test@gmail.com"
                  }, null, 2), [
                    [_vModelText, email.value]
                  ]),
                  (errors.value[0])
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(errors.value[0].message), 1))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _cache[6] || (_cache[6] = _createElementVNode("label", { class: "col-form-label" }, "Password", -1)),
                  _createElementVNode("div", _hoisted_10, [
                    _withDirectives(_createElementVNode("input", {
                      class: _normalizeClass(["form-control", formSubmitted.value ? passwordError.value ? 'is-invalid' : 'is-valid' : '']),
                      type: "password",
                      name: "login[password]",
                      required: "",
                      placeholder: "*********",
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event))
                    }, null, 2), [
                      [_vModelText, password.value]
                    ]),
                    (errors.value[0])
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(errors.value[1].message), 1))
                      : _createCommentVNode("", true),
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "show-hide" }, [
                      _createElementVNode("span", { class: "show" })
                    ], -1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "checkbox p-0" }, [
                    _createElementVNode("input", {
                      id: "checkbox1",
                      type: "checkbox"
                    }),
                    _createElementVNode("label", {
                      class: "text-muted",
                      for: "checkbox1"
                    }, "Remember password")
                  ], -1)),
                  _createElementVNode("a", _hoisted_13, [
                    _createVNode(_component_router_link, { to: "/authentication/forgetpassword" }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" Forgot password?")
                      ])),
                      _: 1
                    })
                  ]),
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-end mt-3" }, [
                    _createElementVNode("button", {
                      class: "btn btn-primary btn-block w-100",
                      type: "submit"
                    }, "Sign in")
                  ], -1))
                ]),
                _cache[14] || (_cache[14] = _createElementVNode("div", { class: "login-social-title" }, [
                  _createElementVNode("h6", null, "Or Sign in with ")
                ], -1)),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("ul", _hoisted_15, [
                    _createElementVNode("li", null, [
                      _createElementVNode("a", _hoisted_16, [
                        _createVNode(_component_vue_feather, { type: "linkedin" })
                      ])
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("a", _hoisted_17, [
                        _createVNode(_component_vue_feather, { type: "twitter" })
                      ])
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("a", _hoisted_18, [
                        _createVNode(_component_vue_feather, { type: "facebook" })
                      ])
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("a", _hoisted_19, [
                        _createVNode(_component_vue_feather, { type: "instagram" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("p", _hoisted_20, [
                  _cache[11] || (_cache[11] = _createTextVNode("Don't have account?")),
                  _createVNode(_component_router_link, {
                    class: "ms-2",
                    to: "/auth/register"
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode(" Create Account")
                    ])),
                    _: 1
                  })
                ])
              ], 32)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})