import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row developer-cards" }
const _hoisted_3 = { class: "col-md-12 developer-Tab" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-md-3 p-0 d-flex" }
const _hoisted_7 = {
  class: "nav nav-tabs border-tab",
  id: "top-tab",
  role: "tablist"
}
const _hoisted_8 = { class: "nav-item" }
const _hoisted_9 = { class: "nav-item" }
const _hoisted_10 = { class: "tab-content" }
const _hoisted_11 = { class: "tab-content-inner" }
const _hoisted_12 = { class: "tab-content-inner" }

import { ref } from "vue";
import { defineAsyncComponent } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'scriptInstallation',
  setup(__props) {

const visitor = defineAsyncComponent(() => import("./visitor.vue"));
const reVisitor = defineAsyncComponent(() => import("./reVisitor.vue"));
const activeTab = ref("visitor");
const showVisitor = ref(true)
const showDevice = ref(true)
let source = sessionStorage.getItem('source')

if(source){
  if(source == 'device'){
    activeTab.value = "identification"
    showVisitor.value = false
  }
  if(source == 'visitor'){
    activeTab.value = "visitor"
    showDevice.value = false
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("ul", _hoisted_7, [
                _withDirectives(_createElementVNode("li", _hoisted_8, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["nav-link", { active: activeTab.value === 'visitor' }]),
                    id: "top-home-tab",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (activeTab.value = 'visitor')),
                    "data-bs-toggle": "tab",
                    href: "#top-home",
                    role: "tab",
                    "aria-controls": "top-home",
                    "aria-selected": "true"
                  }, _cache[2] || (_cache[2] = [
                    _createTextVNode(" Visitor "),
                    _createElementVNode("span", { class: "indicator" }, null, -1)
                  ]), 2)
                ], 512), [
                  [_vShow, showVisitor.value]
                ]),
                _withDirectives(_createElementVNode("li", _hoisted_9, [
                  _createElementVNode("a", {
                    class: _normalizeClass(["nav-link", { active: activeTab.value === 'identification' }]),
                    id: "profile-top-tab",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (activeTab.value = 'identification')),
                    "data-bs-toggle": "tab",
                    href: "#top-profile",
                    role: "tab",
                    "aria-controls": "top-profile",
                    "aria-selected": "false"
                  }, _cache[3] || (_cache[3] = [
                    _createTextVNode(" Identification "),
                    _createElementVNode("span", { class: "indicator" }, null, -1)
                  ]), 2)
                ], 512), [
                  [_vShow, showDevice.value]
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", {
              class: _normalizeClass(["tab-pane fade", { 'show active': activeTab.value === 'visitor' }]),
              id: "top-home",
              role: "tabpanel",
              "aria-labelledby": "top-home-tab"
            }, [
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_unref(visitor))
              ])
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["tab-pane fade", { 'show active': activeTab.value === 'identification' }]),
              id: "top-profile",
              role: "tabpanel",
              "aria-labelledby": "profile-top-tab"
            }, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_unref(reVisitor))
              ])
            ], 2)
          ])
        ])
      ])
    ])
  ]))
}
}

})